import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Divider,
  Drawer,
} from "@mui/material";
import { useRef } from "react";
import { Toast, confirmDialog } from "../../utils/Alert";
import { filterEvents } from "../../utils/Filter";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createEvent,
  fetchEvents,
  deleteEvent,
  getEventDetails,
  editEvent,
} from "../../services/eventsAPI"; //API call
import moment from "moment";
import { useNavigate } from "react-router-dom";

// icons
import EventIcon from "@mui/icons-material/Event";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import VisibilityIcon from "@mui/icons-material/Visibility";

function Events({ isMarketing }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Add Events Drawer
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  //closes event drawer and clear event data
  const closeDrawer = () => {
    setOpen(false);
    setFormData({
      name: "",
      description: "",
      location: "",
      start_date: "",
      end_date: "",
      event_status: "Upcoming",
    });
    setFile(null);
    setFileName(null);
  };

  // Upload Image
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setFileName(file.name);
    }
  };

  const [formData, setFormData] = useState({
    // state for events data
    name: "",
    description: "",
    location: "",
    start_date: "",
    end_date: "",
    event_status: "Upcoming",
  });

  // notifications
  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };

  // Use React Query's useQuery to fetch events
  const { isLoading: loadingEvents, data: events } = useQuery({
    queryKey: ["events"], // Query key for caching and invalidation
    queryFn: fetchEvents, // Function to fetch data
  });

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Define the mutation hook (create Event)
  const mutation = useMutation({
    mutationFn: ({ formData, file }) => createEvent(formData, file),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["events"]); // Refetch events after the new one is created
      notification("success", "Event successfully created");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if any field in formData is empty
    if (Object.values(formData).some((value) => value === "")) {
      notification("error", "Fill in required fields.");
      return;
    }

    // Check date
    const date = new Date();
    const StartDate = new Date(formData.start_date);
    if (formData.start_date > formData.end_date || StartDate < date) {
      notification("error", "Please check date");
      return;
    }
    // Trigger the mutation
    mutation.mutate({ formData, file });
  };

  //Search Events
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = filterEvents(events, searchTerm);

  // Edit Events __________________________________
  const [openEdit, setOpenEdit] = React.useState(false);
  const toggleDrawerEdit = (newOpen) => () => {
    setOpenEdit(newOpen);
  };

  //closes event drawer and clear event data
  const closeDrawerEdit = () => {
    setOpenEdit(false);
    setFormData({
      name: "",
      description: "",
      location: "",
      start_date: "",
      end_date: "",
      event_status: "Upcoming",
    });
    setFile(null);
    setFileName(null);
  };

  // Define the mutation hook (edit Event)
  const editEventmutation = useMutation({
    mutationFn: ({ eventID, formData, file }) =>
      editEvent(eventID, formData, file),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["events"]); // Refetch events after the new one is created
      notification("success", "Event successfully updated");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  const [eventID, setEventID] = React.useState(false); // selected ID for a event
  // Open event
  const handleEditEvent = async (id) => {
    try {
      setOpenEdit(true);
      const eventDetails = await getEventDetails(id);
      setEventID(eventDetails._id);
      const formattedStartDate = eventDetails.start_date
        ? moment(eventDetails.start_date).format("YYYY-MM-DD")
        : "";
      const formattedEndDate = eventDetails.end_date
        ? moment(eventDetails.end_date).format("YYYY-MM-DD")
        : "";
      setFileName(eventDetails.event_wallpaper);
      // Update formData state with event details
      setFormData({
        name: eventDetails.name || "",
        description: eventDetails.description || "",
        location: eventDetails.location || "",
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        event_status: eventDetails.event_status || "Upcoming",
      });
    } catch (error) {
      console.error("Error in handleEditEvent:", error.message);
    }
  };

  // Submit edited event
  const handleSubmitEdit = async () => {
    // Check if any field in formData is empty
    if (Object.values(formData).some((value) => value === "")) {
      notification("error", "Fill in required fields.");
      return;
    }

    // Check date
    const date = new Date();
    const StartDate = new Date(formData.start_date);
    if (formData.start_date > formData.end_date || StartDate < date) {
      notification("error", "Please check date");
      return;
    }
    // Trigger the mutation
    editEventmutation.mutate({ eventID, formData, file });
  };

  // Delete Events______________________
  const deleteEventMutation = useMutation({
    mutationFn: (id) => deleteEvent(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["events"]);
      notification("success", "Event successfully deleted");
    },
  });

  const handleDeleteEvent = async (eventID) => {
    const userConfirmed = await confirmDialog({
      title: "Delete event?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteEventMutation.mutate(eventID);
    }
  };

  return (
    <>
      {/* Events Main Box */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ mb: 2 }} variant="h5">
          Events
        </Typography>
        <Grid container spacing={2}>
          <Grid size={8}>
            {isMarketing && (
              <Button
                variant="contained"
                startIcon={<EventIcon />}
                size="small"
                onClick={() => setOpen(true)}
              >
                Create an Event
              </Button>
            )}
          </Grid>
          <Grid
            size={4}
            container
            justifyContent="flex-end"
            alignItems={"center"}
          >
            {/* <IconButton title="Scan QR" onClick={() => navigate(`/events/QR`)}>
              <QrCodeScannerIcon />
            </IconButton> */}
            <TextField
              label="Search"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          <Grid size={12} sx={{ mb: 2 }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container size={12} spacing={2}>
          {filteredData.length != 0 ? (
            <>
              {filteredData.map((event) => (
                <Grid key={event._id} size={{ xs: 6, sm: 4, lg: 3, xl: 3 }}>
                  <Card sx={{ maxWidth: 435 }}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        transition: "transform 0.2s, box-shadow 0.2s",
                        "&:hover": {
                          transform: "scale(1.02)",
                          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                      onClick={() =>
                        navigate(`/event/${event._id}/${event.name}`)
                      }
                    >
                      <CardHeader
                        sx={{ height: 100 }}
                        action={
                          <Chip
                            size="small"
                            sx={{
                              fontSize: "0.75rem", // Adjust the font size
                              height: "20px", // Reduce the height of the chip
                              padding: "0 4px", // Adjust horizontal padding
                            }}
                            color={
                              event.event_status === "Ongoing"
                                ? "warning"
                                : event.event_status === "Completed"
                                  ? "success"
                                  : "default" // or any other default color
                            }
                            label={event.event_status}
                          />
                        }
                        title={
                          <Typography
                            variant="body"
                            sx={{ fontSize: "1rem", fontWeight: "bold" }}
                          >
                            {event.name}
                          </Typography>
                        }
                        subheader={
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "0.875rem" }}
                          >
                            {moment(event.start_date).format("LL")}
                          </Typography>
                        }
                      />
                      <CardMedia
                        component="img"
                        height="194"
                        image={
                          event.event_wallpaper &&
                          !event.event_wallpaper.includes("undefined")
                            ? event.event_wallpaper
                            : "https://marketing.geospectrum.com.ph/api/Event-Imgs/defaultEvent.jpg"
                        }
                      />
                      <CardContent sx={{ minHeight: 150 }}>
                        
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                            display: "-webkit-box",
                            WebkitLineClamp: 6, // Number of lines to show
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {event.description}
                        </Typography>
                      </CardContent>
                    </Box>

                    <CardActions disableSpacing>
                      {isMarketing && (
                        <IconButton
                          title="Delete"
                          onClick={() => {
                            handleDeleteEvent(event._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                      <IconButton
                        title="Edit"
                        onClick={() => {
                          handleEditEvent(event._id);
                        }}
                      >
                        {isMarketing ? (
                          <ModeEditOutlineIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            "No event"
          )}
        </Grid>
      </Box>

      {/* Drawer for Creating an Event */}
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid size={12} display="flex" alignItems="center">
              <EventIcon />
              <Typography variant="body" sx={{ ml: 1 }}>
                Create Event
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={0.5} columnSpacing={2}>
            <Grid size={12}>
              <Typography variant="body2">Event Name *</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={12} sx={{ mt: 1 }}>
              <Typography variant="body2">Description *</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                multiline
                name="description"
                rows={3}
                value={formData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={12} sx={{ mt: 1 }}>
              <Typography variant="body2">Location *</Typography>
            </Grid>
            <Grid size={12} sx={{ mb: 1 }}>
              <TextField
                fullWidth
                size="small"
                name="location"
                value={formData.location}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Start Date *</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">End Date *</Typography>
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="start_date"
                type="date"
                value={formData.start_date}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={6} sx={{ mb: 5 }}>
              <TextField
                fullWidth
                size="small"
                name="end_date"
                type="date"
                value={formData.end_date}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={9} textAlign={"right"}>
              Upload Wallpaper <br />
              <Typography variant="body2">
                {fileName ? fileName : "No Image Selected"}
              </Typography>
            </Grid>
            <Grid size={3}>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the actual file input
              />
              <IconButton onClick={handleIconClick}>
                <FileUploadIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Grid>
            <Grid size={12} container justifyContent="flex-end" sx={{ mt: 3 }}>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={closeDrawer}
              >
                Cancel
              </Button>
              <Button variant="contained" size="small" onClick={handleSubmit}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>

      {/* Drawer for Editing an Event */}
      <Drawer open={openEdit} onClose={() => closeDrawerEdit()}>
        <Box
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid size={12} display="flex" alignItems="center">
              <EventIcon />
              <Typography variant="body">Edit Event</Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }} spacing={0.5} columnSpacing={2}>
            <Grid size={12}>
              <Typography variant="body2">Event Name</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="name"
                disabled={!isMarketing}
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </Grid>
            <Grid size={12} sx={{ mt: 1 }}>
              <Typography variant="body2">Description</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                multiline
                name="description"
                rows={3}
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </Grid>
            <Grid size={12} sx={{ mt: 1 }}>
              <Typography variant="body2">Location</Typography>
            </Grid>
            <Grid size={12} sx={{ mb: 1 }}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="location"
                value={formData.location}
                onChange={(e) =>
                  setFormData({ ...formData, location: e.target.value })
                }
              />
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Start Date</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">End Date</Typography>
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="start_date"
                type="date"
                disabled={!isMarketing}
                value={formData.start_date}
                onChange={(e) =>
                  setFormData({ ...formData, start_date: e.target.value })
                }
              />
            </Grid>
            <Grid size={6} sx={{ mb: 5 }}>
              <TextField
                fullWidth
                size="small"
                name="end_date"
                type="date"
                disabled={!isMarketing}
                value={formData.end_date}
                onChange={(e) =>
                  setFormData({ ...formData, end_date: e.target.value })
                }
              />
            </Grid>
            {isMarketing && (
              <>
                <Grid size={9} textAlign={"right"}>
                  Update Wallpaper <br />
                  <Typography variant="body2">
                    {fileName ? fileName : "No Image Selected"}
                  </Typography>
                </Grid>

                <Grid size={3}>
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }} // Hide the actual file input
                  />
                  <IconButton onClick={handleIconClick}>
                    <FileUploadIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>

                <Grid
                  size={12}
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 3 }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={closeDrawerEdit}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleSubmitEdit();
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}

export default Events;
